import { inView, animate, spring, stagger } from "motion"

document.addEventListener('turbo:load', function () {
  var links = document.querySelectorAll('ul.who-needs-links span')
  var tabs = document.querySelector('.who-needs-content').children
  var business_type = document.querySelector('#business_type')
  var faqs = document.querySelectorAll('.faq-section')
  var back = document.querySelector('.background_color')

  function hide_current(image) {
    animate(image.querySelector('img'), { opacity: [1, 0], x: [0, -100] }, { duration: 0.5, easing: spring() })
    animate(image.querySelector('.card'), { opacity: [1, 0], x: [0, 100] }, { duration: 0.5, easing: spring() })
    image.classList.add('tw-hidden');
  }

  function show_current(image) {
    image.classList.remove('tw-hidden');
    animate(image.querySelector('img'), { opacity: [0, 1], x: [-500, 0] }, { duration: 1, easing: spring() })
    animate(image.querySelector('.card'), { opacity: [0, 1], x: [500, 0] }, { duration: 1, easing: spring() })
  }

  function animate_left(image) {
    animate(image, { opacity: [0, 1], x: [-100, 0] }, { duration: 1, easing: spring() })
  }

  function animate_right(image) {
    animate(image, { opacity: [0, 1], x: [100, 0] }, { duration: 0.5, easing: spring() })
  }

  inView('.jumbotron', (e) => {
    animate('.jumbotron-image', { opacity: [0, 1], x: [100, 0] }, { duration: 0.5, easing: "ease-in-out" })
  })

  business_type.addEventListener('change', e => {
    for(const t of tabs) { t.classList.add('tw-hidden') }
    tabs[business_type.value].classList.remove('tw-hidden')
    animate_left(tabs[business_type.value].querySelector('img'))
    animate_right(tabs[business_type.value].querySelector('.card'))
  })

  links.forEach(link => {
    link.addEventListener('click', e => {
      var tab = e.currentTarget.id

      if(tabs[tab]) {
        for(const t of tabs) {
          if(!t.classList.contains('tw-hidden')) {
            console.log(t);

            hide_current(t)
          }
        }
        for(const l of links) { l.classList.remove('active-link') } 

        e.currentTarget.classList.add('active-link')

        show_current(tabs[tab])
      }
    })
  })

  inView('.feature-images', (e) => {
    animate(e.target.querySelector('img'), { opacity: [0, 1], x: [-100, 0] }, { delay: 0.2, duration: 1 });
    animate(e.target.querySelector('.background_color'), { opacity: [0, 1], x: [0, -40], y: [0, -40] }, { delay: 0.2, duration: 2, easing: 'ease-out'});
  })

  inView('.competitive', (e) => {
    animate(".competitive li",
      { x: [200, 0] },
      {
        delay: stagger(0.3),
        duration: 0.9,
        easing: [.22, .03, .26, 1]
      }
    )
  })
  
  inView(".faq-content", (e) => {
    animate(".faq-section",
      { x: [200, 0] },
      {
        delay: stagger(0.3),
        duration: 0.9,
        easing: [.22, .03, .26, 1]
      }
    )
  })

  faqs.forEach(faq => {
    faq.querySelector('h4').addEventListener('click', (e) => {
      if(faq.dataset.open == 'false') {
        animate(faq.querySelector('p'), { opacity: [0, 1], height: ['0px', `${faq.querySelector('p').scrollHeight}px`] }, { delay: 0.1, duration: 1 });
        animate(faq.querySelector('.plus'), { transform: "rotate(45deg)" }, { delay: 0 } )
        faq.querySelector('.plus svg').style.fill = "black";
        faq.dataset.open = 'true'
      } else {
        animate(faq.querySelector('p'), { opacity: [1, 0], height: ['50px', '0px'] }, { delay: 0.1, duration: 1 });
        animate(faq.querySelector('.plus'), { transform: "rotate(90deg)" }, { delay: 0 } )
        faq.querySelector('.plus svg').style.fill = "#6b7280";
        faq.dataset.open = 'false'
      }
      faq.querySelector('p').classList.toggle('tw-mt-5')
      faq.querySelector('p').classList.toggle('tw-invisible')
    })
  })
});
